<template>
  <ModuleComponent
    name="consultants"
    :module-data="moduleData"
    @changed="item = $event"
    @beforeSaveItem="beforeSaveItem"
    @beforeItemLoad="beforeItemLoad"
  >
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="filters.name.value"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.EMAIL')">
              <b-input-group prepend="@" :label="t('GLOBAL.EMAIL')">
                <b-form-input
                  v-model="filters.email.value"
                  type="email"
                  :placeholder="t('GLOBAL.EMAIL')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.CONSULTANTS.D')">
              <v-select2
                v-model="filters.id.value"
                endPoint="accounts"
                :placeholder="t('GLOBAL.CONSULTANTS.D')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Consultant }
                }"
                sort="name"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
              <vue-select
                v-model="filters.active.value"
                label="text"
                :options="[
                  { text: t('GLOBAL.ACTIVE'), value: true },
                  { text: t('GLOBAL.PASSIVE'), value: false }
                ]"
                :multiple="false"
                :placeholder="t('GLOBAL.ALL')"
              >
              </vue-select>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.COUNTRY')" class="mb-3">
              <v-select2
                v-model="filters.countryId.value"
                :text="''"
                endPoint="locations"
                :placeholder="t('GLOBAL.COUNTRY')"
                sort="order"
                :filters="{ type: { value: 0 } }"
                @input="
                  filters.cityId.value = null;
                  filters.countyId.value = null;
                  filters.districtId.value = null;
                "
                :name="t('GLOBAL.COUNTRY')"
              ></v-select2>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3" v-if="filters.countryId.value > 0">
            <b-form-group :label="t('GLOBAL.CITY')" class="mb-3">
              <v-select2
                v-model="filters.cityId.value"
                :text="''"
                endPoint="locations"
                :placeholder="t('GLOBAL.CITY')"
                :filters="{
                  type: { value: 1 },
                  parentId: { value: filters.countryId.value }
                }"
                @input="
                  filters.countyId.value = null;
                  filters.districtId.value = null;
                "
              ></v-select2>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3" v-if="filters.cityId.value > 0">
            <b-form-group :label="t('GLOBAL.COUNTY')" class="mb-3">
              <v-select2
                v-model="filters.countyId.value"
                :text="''"
                endPoint="locations"
                :placeholder="t('GLOBAL.COUNTY')"
                :filters="{
                  type: { value: 2 },
                  parentId: { value: filters.cityId.value }
                }"
                @input="filters.districtId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3" v-if="filters.countyId.value > 0">
            <b-form-group :label="t('GLOBAL.DISTRICT')" class="mb-3">
              <v-select2
                v-model="filters.districtId.value"
                :text="''"
                endPoint="locations"
                :placeholder="t('GLOBAL.DISTRICT')"
                :filters="{
                  type: { value: 3 },
                  parentId: { value: filters.countyId.value }
                }"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:customColumn_account.active="{ rowItem }">
      <TableStatusColumn v-model="rowItem.account.active"></TableStatusColumn>
    </template>
    <template v-slot:form="{ item }">
      <b-tabs content-class="mt-3" class="mt-3 form-group-mb-0">
        <b-tab :title="t('GLOBAL.PERSONALINFO')" active>
          <b-row>
            <b-col cols="12" sm="12" md="12">
              <b-form-group label="">
                <b-form-radio-group
                  v-model="item.account.isCompany"
                  :options="isCompanyOptions"
                  name="isCompany"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="12" md="6">
              <b-form-group :label="t('GLOBAL.FULLNAME')">
                <b-form-input v-model="item.account.name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="12" md="6">
              <b-form-group :label="t('GLOBAL.IDENTIFYNO')">
                <b-form-input v-model="item.account.identityNo"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="12" md="6">
              <b-form-group :label="t('GLOBAL.BIRTHDAY')">
                <BDatePicker
                  :id="'birthDate'"
                  v-model="item.account.birthDate"
                  @input="item.account.birthDate = $event"
                  hide-header
                  show-decade-nav
                  :placeholder="t('GLOBAL.BIRTHDAY')"
                >
                </BDatePicker>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="6" md="6">
              <b-form-group :label="t('GLOBAL.SELECTLANGUAGE')">
                <v-select2
                  v-model="item.account.languageId"
                  :text="
                    item.account.language ? item.account.language.name : ''
                  "
                  endPoint="languages"
                  :placeholder="t('GLOBAL.SELECTLANGUAGE')"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="6" md="4">
              <b-form-group :label="t('GLOBAL.COMPANIES')">
                <v-select2
                  v-model="companies"
                  endPoint="companies"
                  :placeholder="t('GLOBAL.COMPANIES')"
                  multiple
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="6" md="4">
              <b-form-group :label="t('GLOBAL.TAGS')">
                <input-tag
                  v-model="tags"
                  @input="item.account.tags = $event.join(',')"
                ></input-tag>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <b-form-group :label="t('GLOBAL.PROFILETEXT')">
                <TinyEditor v-model="item.account.bio" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <Upload
                v-model="item.account.profileImage"
                single
                :title="t('GLOBAL.PROFILEIMAGE')"
              />
            </b-col>
            <b-col cols="4">
              <Upload
                v-model="item.account.profileCoverImage"
                single
                :title="t('GLOBAL.PROFILECOVERIMAGE')"
              />
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-checkbox
                size="lg"
                switch
                v-model="item.account.active"
                name="active"
                >{{ t("GLOBAL.ACTIVE") }}
              </b-form-checkbox>
              <div class="clearfix"></div>
              <b-form-checkbox
                switch
                size="lg"
                v-model="item.account.smsAllowed"
                name="smsAllowed"
                >{{ t("GLOBAL.SMSALLOWED") }}
              </b-form-checkbox>
              <div class="clearfix"></div>
              <b-form-checkbox
                switch
                size="lg"
                v-model="item.account.emailAllowed"
                name="emailAllowed"
                >{{ t("GLOBAL.EMAILALLOWED") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="t('GLOBAL.CONTACTINFO')">
          <b-row>
            <b-col cols="12" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PHONE')">
                <IntlTel v-model="item.account.phone"></IntlTel>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.EMAIL')">
                <b-input-group prepend="@">
                  <b-form-input
                    v-model="item.account.email"
                    type="email"
                    :placeholder="t('GLOBAL.EMAIL')"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PASSWORD')">
                <b-input-group prepend="#" v-if="item.id == 0 || showPassword">
                  <b-form-input
                    v-model="item.account.password"
                    type="password"
                    :placeholder="t('GLOBAL.PASSWORD')"
                    :name="t('GLOBAL.PASSWORD')"
                  ></b-form-input>
                </b-input-group>
                <b-form-checkbox
                  size="lg"
                  switch
                  v-model="showPassword"
                  name="showPassword"
                  v-if="item.id > 0"
                  >{{ t("GLOBAL.CHANGEPASSWORD") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.WEBSITE')">
                <b-input-group prepend="https://">
                  <b-form-input
                    v-model="item.account.web"
                    :placeholder="t('GLOBAL.WEBSITE')"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="4" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.COUNTRY')" class="mb-3">
                <v-select2
                  v-model="item.account.countryId"
                  :text="item.account.country ? item.account.country.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTRY')"
                  sort="order"
                  :filters="{ type: { value: 0 } }"
                  @input="
                    item.account.cityId = 0;
                    item.account.countyId = 0;
                    item.account.districtId = 0;
                  "
                  :name="t('GLOBAL.COUNTRY')"
                  v-validate.continues="'required'"
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.CITY')" class="mb-3">
                <v-select2
                  v-model="item.account.cityId"
                  :text="item.account.city ? item.account.city.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.CITY')"
                  :filters="{
                    type: { value: 1 },
                    parentId: { value: item.account.countryId }
                  }"
                  @input="
                    item.account.countyId = 0;
                    item.account.districtId = 0;
                  "
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.COUNTY')" class="mb-3">
                <v-select2
                  v-model="item.account.countyId"
                  :text="item.account.county ? item.account.county.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTY')"
                  :filters="{
                    type: { value: 2 },
                    parentId: { value: item.account.cityId }
                  }"
                  @input="item.account.districtId = 0"
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.DISTRICT')" class="mb-3">
                <v-select2
                  v-model="item.account.districtId"
                  :text="
                    item.account.district ? item.account.district.name : ''
                  "
                  endPoint="locations"
                  :placeholder="t('GLOBAL.DISTRICT')"
                  :filters="{
                    type: { value: 3 },
                    parentId: { value: item.account.countyId }
                  }"
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.ZIPCODE')" class="mb-3">
                <b-form-input v-model="item.account.zipCode"></b-form-input>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.ADDRESS')" class="mb-3">
                <b-form-textarea
                  rows="5"
                  v-model="item.account.address"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="8" sm="12" md="8">
              <b-form-group :label="t('GLOBAL.LOCATION')">
                <Map
                  v-if="item"
                  :lat="item.account.lat"
                  :lng="item.account.lng"
                  @change="
                    item.account.lat = $event.lat;
                    item.account.lng = $event.lng;
                  "
                ></Map>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="t('GLOBAL.COMMERCIALINFO')">
          <b-row>
            <b-col cols="12" sm="12" md="12">
              <b-form-group :label="t('GLOBAL.COMPANYNAME')">
                <b-form-input v-model="item.account.company"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" md="6">
              <b-form-group :label="t('GLOBAL.TAXNO')">
                <b-form-input v-model="item.account.taxNo"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" md="6">
              <b-form-group :label="t('GLOBAL.TAXOFFICE')">
                <b-form-input
                  v-model="item.account.taxOffice"
                  :label="t('GLOBAL.TAXOFFICE')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Consultant
export default {
  name: "consultants",
  methods: {
    beforeItemLoad(item) {
      this.showPassword = false;
      this.companies = item.account.companies.map(p => {
        return p.companyId;
      });
    },
    beforeSaveItem(item) {
      item.account.companies = this.companies.map(p => {
        return { accountId: item.id, companyId: p };
      });
    }
  },
  data() {
    return {
      tags: [],
      showPassword: false,
      isCompanyOptions: [
        { text: this.t("GLOBAL.INDIVIDUAL"), value: 1 },
        { text: this.t("GLOBAL.CORPORATE"), value: 0 }
      ],
      companies: [],
      item: {},
      moduleData: {
        name: "consultants",
        primaryKey: "id",
        table: {
          sortBy: "account.name",
          sortDesc: false,
          graphqlQuery: `
							id,
							account{
	                                id,
	                                email,
	                                password,
	                                active,
	                                accountTypeId,
	                                name,
	                                languageId,
	                                phone,
	                                countryId,
	                                cityId,
	                                countyId,
	                                districtId,
	                                identityNo,
	                                birthDate,
	                                isCompany,
	                                company,
	                                taxOffice,
	                                taxNo,
	                                web,
	                                zipCode,
	                                address,
	                                lng,
	                                lat,
	                                bio,
	                                profileImage,
	                                profileCoverImage,
	                                emailAllowed,
	                                smsAllowed,
	                                verifyKey
							      }
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.NAME"), value: "account.name" },
            { text: this.t("GLOBAL.EMAIL"), value: "account.email" },
            { text: this.t("GLOBAL.PHONE"), value: "account.phone" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "account.active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["account.active"],
          filters: {
            id: {
              type: "number",
              value: null
            },
            name: {
              field: "account.name",
              type: "string",
              searchBy: "contains",
              value: null
            },
            email: {
              field: "account.email",
              searchBy: "contains",
              type: "string",
              value: null
            },
            countryId: {
              field: "account.countryId",
              type: "number",
              value: null
            },
            cityId: {
              field: "account.cityId",
              type: "number",
              value: null
            },
            countyId: {
              field: "account.countyId",
              type: "number",
              value: null
            },
            districtId: {
              field: "account.districtId",
              type: "number",
              value: null
            },
            active: {
              field: "account.active",
              type: "bool",
              value: null
            }
          }
        },
        form: {
          fullScreen: true
        },
        fileManager: "consultant_file_groups"
      }
    };
  },
  watch: {
    item(obj) {
      this.tags = obj.account.tags ? obj.account.tags.split(",") : [];
    }
  }
};
</script>
